import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {EMPTY, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {
    MSAL_INTERCEPTOR_CONFIG,
    MsalInterceptorAuthRequest,
    MsalInterceptorConfiguration,
    MsalService,
    ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
    AccountInfo,
    AuthenticationResult,
    BrowserConfigurationAuthError,
    InteractionType,
    StringUtils,
    UrlString,
} from '@azure/msal-browser';
import {Location} from '@angular/common';


// Based on code from MSFT MSAL implementation in v2.0
// See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/ffbe488114d979f012734225d0df725539971564/lib/msal-angular/src/msal.interceptor.ts
//
// Changes the default implementation by attempting to perform HTTP requests to anonymous API endpoints
// regardless login requirement (see line with account === undefined if-statement).
//
// This class was added to work around the default MSAL implementation to allow performing HTTP requests
// to endpoints with OR without the auth token.
@Injectable()
export class MsalCustomInterceptor implements HttpInterceptor {
    constructor(
        @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
        private authService: MsalService,
        private location: Location,
    ) {
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.msalInterceptorConfig.interactionType !== InteractionType.Popup && this.msalInterceptorConfig.interactionType !== InteractionType.Redirect) {
            throw new BrowserConfigurationAuthError('invalid_interaction_type', 'Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, InteractionType.Redirect must be provided in the msalInterceptorConfiguration');
        }

        this.authService.getLogger().verbose('MSAL Interceptor activated');
        const scopes = this.getScopesForEndpoint(req.url, req.method);

        // If no scopes for endpoint, does not acquire token
        if (!scopes || scopes.length === 0) {
            this.authService.getLogger().verbose('Interceptor - no scopes for endpoint');
            return next.handle(req);
        }

        // Sets account as active account or first account
        let account: AccountInfo;
        if (!!this.authService.instance.getActiveAccount()) {
            this.authService.getLogger().verbose('Interceptor - active account selected');
            account = this.authService.instance.getActiveAccount();
        } else {
            this.authService.getLogger().verbose('Interceptor - no active account, fallback to first account');
            account = this.authService.instance.getAllAccounts()[0];
        }

        const authRequest = typeof this.msalInterceptorConfig.authRequest === 'function'
            ? this.msalInterceptorConfig.authRequest(this.authService, req, {account: account})
            : {...this.msalInterceptorConfig.authRequest, account};

        this.authService.getLogger().verbose(`Interceptor - ${scopes.length} scopes found for endpoint`);
        this.authService.getLogger().verbosePii(`Interceptor - [${scopes}] scopes found for ${req.url}`);

        // David Yee:
        // Attempt request with user login optional
        // This is the custom override of the default MSAL behaviour and allowed for protected endpoints to be
        // called "anonymously" (e.g. without the Authorization: Bearer header token)
        if (account === undefined) {
            return next.handle(req);
        }

        // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
        return this.authService.acquireTokenSilent({...authRequest, scopes, account})
            .pipe(
                catchError((err, caught) => {
                    this.authService.getLogger().error('Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.');
                    return this.acquireTokenInteractively(authRequest, scopes);
                }),
                switchMap((result: AuthenticationResult) => {
                    if (!result.accessToken) {
                        this.authService.getLogger().error('Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve.');
                        return this.acquireTokenInteractively(authRequest, scopes);
                    }
                    return of(result);
                }),
                switchMap((result: AuthenticationResult) => {
                    this.authService.getLogger().verbose('Interceptor - setting authorization headers');
                    const headers = req.headers
                        .set('Authorization', `Bearer ${result.accessToken}`);

                    const requestClone = req.clone({headers});
                    return next.handle(requestClone);
                }),
            );
    }

    /**
   * Invoke interaction for the given set of scopes
   * @param scopes Array of scopes for the request
   * @returns Result from the interactive request
   */
    private acquireTokenInteractively(authRequest: MsalInterceptorAuthRequest, scopes: string[]): Observable<AuthenticationResult> {
        if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
            this.authService.getLogger().verbose('Interceptor - error acquiring token silently, acquiring by popup');
            return this.authService.acquireTokenPopup({...authRequest, scopes});
        }
        this.authService.getLogger().verbose('Interceptor - error acquiring token silently, acquiring by redirect');
        const redirectStartPage = window.location.href;
        this.authService.acquireTokenRedirect({...authRequest, scopes, redirectStartPage});
        return EMPTY;
    }

    /**
   * Looks up the scopes for the given endpoint from the protectedResourceMap
   * @param endpoint Url of the request
   * @param endpoint Http method of the request
   * @returns Array of scopes, or null if not found
   *
   */
    public getScopesForEndpoint(endpoint: string, httpMethod: string): Array<string> | null {
        this.authService.getLogger().verbose('Interceptor - getting scopes for endpoint');

        // Ensures endpoints and protected resources compared are normalized
        const normalizedEndpoint = this.location.normalize(endpoint);

        const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());

        const matchingProtectedResources = this.matchResourcesToEndpoint(<string[]>protectedResourcesArray, normalizedEndpoint);

        if (matchingProtectedResources.length > 0) {
            return this.matchScopesToEndpoint(this.msalInterceptorConfig.protectedResourceMap, matchingProtectedResources, httpMethod);
        }

        return null;
    }

    /**
   * Finds resource endpoints that match request endpoint
   * @param protectedResourcesArray
   * @param endpoint
   * @param location
   * @returns
   */
    private matchResourcesToEndpoint(protectedResourcesEndpoints: string[], endpoint: string): Array<string> {
        return protectedResourcesEndpoints.filter(key => {
            const normalizedKey = this.location.normalize(key);

            // Normalized key should include query strings if applicable
            const keyComponents = new UrlString(key).getUrlComponents();
            const relativeNormalizedKey = keyComponents.QueryString ? `${keyComponents.AbsolutePath}?${keyComponents.QueryString}` : this.location.normalize(keyComponents.AbsolutePath);

            // Relative endpoint not applicable, matching endpoint with protected resource. StringUtils.matchPattern accounts for wildcards
            if (relativeNormalizedKey === '' || relativeNormalizedKey === '/*') {
                return StringUtils.matchPattern(normalizedKey, endpoint);
            } else {
                // Matching endpoint with both protected resource and relative url of protected resource
                return StringUtils.matchPattern(normalizedKey, endpoint) || StringUtils.matchPattern(relativeNormalizedKey, endpoint);
            }
        });
    }

    /**
   * Finds scopes from first matching endpoint with HTTP method that matches request
   * @param protectedResourceMap Protected resource map
   * @param endpointArray Array of resources that match request endpoint
   * @param httpMethod Http method of the request
   * @returns
   */
    private matchScopesToEndpoint(protectedResourceMap: Map<string, Array<string | ProtectedResourceScopes> | null>, endpointArray: string[], httpMethod: string): Array<string> | null {
        const allMatchedScopes = [];

        // Check each matched endpoint for matching HttpMethod and scopes
        endpointArray.forEach(matchedEndpoint => {
            const scopesForEndpoint = [];
            const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint);

            // Return if resource is unprotected
            if (methodAndScopesArray === null) {
                allMatchedScopes.push(null);
                return;
            }

            methodAndScopesArray.forEach(entry => {
                // Entry is either array of scopes or ProtectedResourceScopes object
                if (typeof entry === 'string') {
                    scopesForEndpoint.push(entry);
                } else {
                    // Ensure methods being compared are normalized
                    const normalizedRequestMethod = httpMethod.toLowerCase();
                    const normalizedResourceMethod = entry.httpMethod.toLowerCase();

                    // Method in protectedResourceMap matches request http method
                    if (normalizedResourceMethod === normalizedRequestMethod) {
                        entry.scopes.forEach(scope => {
                            scopesForEndpoint.push(scope);
                        });
                    }
                }
            });

            // Only add to all scopes if scopes for endpoint and method is found
            if (scopesForEndpoint.length > 0) {
                allMatchedScopes.push(scopesForEndpoint);
            }
        });

        if (allMatchedScopes.length > 0) {
            if (allMatchedScopes.length > 1) {
                this.authService.getLogger().warning('Interceptor - More than 1 matching scopes for endpoint found.');
            }
            // Returns scopes for first matching endpoint
            return allMatchedScopes[0];
        }

        return null;
    }

}
