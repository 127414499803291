import { SeparateCamelCasePipe } from './separate-camel-case.pipe';
import { NgModule } from '@angular/core';
import { ArrayFilterPipe } from './array-filter.pipe';
import { SameDayPipe } from './same-day.pipe';

@NgModule({
  imports: [],
  declarations: [
    SeparateCamelCasePipe,
    ArrayFilterPipe,
    SameDayPipe,
  ],
  exports: [
    SeparateCamelCasePipe,
    ArrayFilterPipe,
    SameDayPipe,
  ],
})
export class ApplicationPipesModule {
}
