import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

// Based on code by marcos-dimitrio (https://stackoverflow.com/users/4306798/marcos-dimitrio)
// From https://stackoverflow.com/a/57530087
// Licensed under CC-BY-SA 4.0
export const InterceptSkipErrorHeader = 'X-Skip-Error-Interceptor';

@Injectable({
    providedIn: 'root',
})
export class BlobErrorHttpInterceptorService implements HttpInterceptor {

    // https://stackoverflow.com/a/49047764
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has(InterceptSkipErrorHeader)) {
            const headers = req.headers.delete(InterceptSkipErrorHeader);
            return next.handle(req.clone({headers}));
        }
        return next.handle(req).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.error instanceof Blob && error.error.type === 'application/json') {
                    // https://github.com/angular/angular/issues/19888
                    // When request of type Blob, the error is also in Blob instead of object of the json data
                    return new Promise<any>((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (e: Event) => {
                            try {
                                const errmsg = JSON.parse((<any>e.target).result);
                                reject(new HttpErrorResponse({
                                    error: errmsg,
                                    headers: error.headers,
                                    status: error.status,
                                    statusText: error.statusText,
                                    url: error.url || undefined,
                                }));
                            } catch (e) {
                                reject(error);
                            }
                        };
                        reader.onerror = (e) => {
                            reject(error);
                        };
                        reader.readAsText(error.error);
                    });
                }
                return throwError(error);
            }),
        );
    }
}
