import {Injectable, OnDestroy} from '@angular/core';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {NbDialogService} from '@nebular/theme';
import {CookieService} from 'ngx-cookie-service';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ConfirmPromptComponent} from '../../@theme/components/modal/confirm-prompt.component';
import {ApplicationInsightsService} from '../utils/application-insights.service';
import {User} from './user';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

// Based on code by alain-charles (https://github.com/alain-charles)
// From https://github.com/akveo/nebular/issues/706#issuecomment-421294590
@Injectable()
export class UserService implements OnDestroy {

    protected user: BehaviorSubject<User | any> = new BehaviorSubject(this.getUser);
    private readonly destroy$ = new Subject<boolean>();
    private readonly cookieKey = `msal.${ environment.azureClientId }.idtoken`;

    constructor(
        private logger: NGXLogger,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private cookieService: CookieService,
        private dialogService: NbDialogService,
        private applicationInsightService: ApplicationInsightsService,
    ) {
        this.onUserChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (user) => {
                    if (user == null) {
                        this.applicationInsightService.clearUserId();
                    } else {
                        this.applicationInsightService.setUserId(user.preferred_username || user.name || user.oid);
                    }
                },
            });
    }

    private get getUser(): User | any {
        const currentUser = this.authService.instance.getActiveAccount();
        if (currentUser != null) {
            return currentUser.idTokenClaims;
        } else {
            return null;
        }
    }

    publishUser(user: User | any): void {
        this.user.next(user);
    }

    onUserChange(): Observable<User | any> {
        return this.user;
    }

    signOutUser() {
        this.dialogService.open(ConfirmPromptComponent, {
            hasBackdrop: true, closeOnEsc: true, autoFocus: true,
            context: {
                title: 'Are you sure you want to logout?',
                body: 'You will lose any data that has not been saved yet!',
                button3Text: 'Cancel',
                button3Result: false,
                button3Class: 'btn btn-info float-end',
                button1Text: 'Logout',
                button1Result: true,
            },
        }).onClose
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (confirm) => {
                    if (confirm === true) {
                        this.applicationInsightService.clearUserId();
                        this.authService.logoutRedirect();
                        sessionStorage.clear();
                        this.cookieService.deleteAll('/', window.location.hostname);
                    }
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
		this.destroy$.complete();
    }

    // Useful for saving JWT idToken e.g. for Hangfire
    saveIdToken(idToken: string, date: Date) {
        if (idToken == null)
            this.cookieService.delete(this.cookieKey, '/');
        else {
            this.cookieService.set(this.cookieKey, idToken, date, '/');
        }
    }

}
