import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AppUserService } from './api/appUser.service';
import { BOMImportFileService } from './api/bOMImportFile.service';
import { BOMImportQuarantineService } from './api/bOMImportQuarantine.service';
import { CompanyService } from './api/company.service';
import { CountryService } from './api/country.service';
import { CrewDailyLogService } from './api/crewDailyLog.service';
import { CrewDayService } from './api/crewDay.service';
import { CrewDayPersonnelService } from './api/crewDayPersonnel.service';
import { CrewDayPersonnelProjectExpensesService } from './api/crewDayPersonnelProjectExpenses.service';
import { CrewDayPersonnelTradeService } from './api/crewDayPersonnelTrade.service';
import { CrewDayProjectService } from './api/crewDayProject.service';
import { CrewDayTaskService } from './api/crewDayTask.service';
import { CrewDayTaskEquipmentService } from './api/crewDayTaskEquipment.service';
import { CrewDayTaskProductService } from './api/crewDayTaskProduct.service';
import { CrewLeadService } from './api/crewLead.service';
import { CrewPersonnelService } from './api/crewPersonnel.service';
import { DailyLogService } from './api/dailyLog.service';
import { DailyLogEquipmentService } from './api/dailyLogEquipment.service';
import { EquipmentService } from './api/equipment.service';
import { EquipmentCategoryService } from './api/equipmentCategory.service';
import { EquipmentSubCategoryService } from './api/equipmentSubCategory.service';
import { EquipmentVendorService } from './api/equipmentVendor.service';
import { ExpenseService } from './api/expense.service';
import { FormService } from './api/form.service';
import { MetadataService } from './api/metadata.service';
import { PersonnelService } from './api/personnel.service';
import { PersonnelTimeRecordService } from './api/personnelTimeRecord.service';
import { PersonnelTradeUnionService } from './api/personnelTradeUnion.service';
import { ProductService } from './api/product.service';
import { ProjectService } from './api/project.service';
import { ProjectBrassingService } from './api/projectBrassing.service';
import { ProjectBreakdownService } from './api/projectBreakdown.service';
import { ProjectChangeService } from './api/projectChange.service';
import { ProjectCompanyTimeImportService } from './api/projectCompanyTimeImport.service';
import { ProjectEquipmentRateService } from './api/projectEquipmentRate.service';
import { ProjectExpenseService } from './api/projectExpense.service';
import { ProjectPersonnelService } from './api/projectPersonnel.service';
import { ProjectPremiumService } from './api/projectPremium.service';
import { ProjectTaskService } from './api/projectTask.service';
import { ProjectTradeUnionService } from './api/projectTradeUnion.service';
import { ProjectWorkTaskService } from './api/projectWorkTask.service';
import { StateProvinceService } from './api/stateProvince.service';
import { SystemOptionService } from './api/systemOption.service';
import { TimeImportQuarantineService } from './api/timeImportQuarantine.service';
import { TradeUnionService } from './api/tradeUnion.service';
import { WorkAreaService } from './api/workArea.service';
import { WorkShiftService } from './api/workShift.service';
import { WorkTaskService } from './api/workTask.service';
import { WorkTradeService } from './api/workTrade.service';
import { WorkTradeUnionService } from './api/workTradeUnion.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
