import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {CrewLead, CrewLeadService} from '../../../sitewerx-api';
import {NGXLogger} from 'ngx-logger';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CrewLeaderService implements OnDestroy {

    static readonly StorageKey = 'crewlead';
    protected crewLead: BehaviorSubject<CrewLead> = new BehaviorSubject(null);
    protected destroy$ = new Subject<boolean>();
    private refreshingCrewLeadSub: Subscription;

    private defaultCrewLeadExpand: string = 'crewLeader,defaultProject';

    constructor(
        private logger: NGXLogger,
        private router: Router,
        private crewLeadService: CrewLeadService,
    ) {
        if (sessionStorage.getItem(CrewLeaderService.StorageKey)) {
            const crewLead: CrewLeadCheck = JSON.parse(sessionStorage.getItem(CrewLeaderService.StorageKey));

            this.publishCrewLeader(crewLead);

            // refresh the cached copy
            this.refreshCrewLeadAndPublish(crewLead);
        }
    }

    refreshCrewLeadAndPublish(crewLead: CrewLeadCheck = null) {
        if (crewLead != null && crewLead.id != null) {
            this.refreshingCrewLeadSub = this.getCrewLeadById(crewLead.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe({next:(e) => this.publishCrewLeader(e)});
        }
    }

    getCrewLeadById(id: number): Observable<CrewLeadCheck> {
        return this.crewLeadService.crewLeadGetById(
            id,
            undefined,
            this.defaultCrewLeadExpand,
            "body");
    }

    publishCrewLeader(crewLead: CrewLeadCheck) {
    // Cancel cache refresh if we're changing the crewLead before the cache is refreshed
        if (this.refreshingCrewLeadSub) {
            this.refreshingCrewLeadSub.unsubscribe();
        }

        this.crewLead.next(crewLead);
        sessionStorage.setItem(CrewLeaderService.StorageKey, JSON.stringify(this.crewLead.value));
    // this.logger.trace('Persisting crewLead number to session storage: ' + JSON.stringify(this.crewLead.value));
    }

    onCrewLeadChange(): Observable<CrewLeadCheck> {
        return this.crewLead;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }
}

export interface CrewLeadCheck extends CrewLead {
    approvals?: Array<CrewLead>;
}
