import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {CookieService} from 'ngx-cookie-service';
import {MicrosoftGraphService} from '../microsoft-graph/microsoft-graph.service';
import {StatePersistingService} from '@framewerx/kendo-angular-grid';
import {StateService} from './state.service';
import {UserService} from './users.service';
import {PersonnelTimeGridService} from './grid/personnel-time-grid.service';
import {CrewDayPersonnelTradeGridService} from './grid/crew-day-personnel-trade-grid.service';
import {CrewLeaderService} from './crew-leader.service';
import {PersonnelSearchGridService} from './grid/personnel-search-grid.service';
import {CrewDayTaskGridService} from './grid/crew-day-task-grid.service';

const SERVICES = [
    CookieService,
    UserService,

    StateService,
    MicrosoftGraphService,
    StatePersistingService,
    PersonnelTimeGridService,
    CrewDayPersonnelTradeGridService,
    CrewDayTaskGridService,
    CrewLeaderService,
    PersonnelSearchGridService,
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers: [
        ...SERVICES,
    ],
    exports: [],
})
export class DataModule {
    static forRoot(): ModuleWithProviders<DataModule> {
        return {
            ngModule: DataModule,
            providers: [
                ...SERVICES,
            ],
        };
    }
}
