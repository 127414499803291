import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList, ViewChild,
  ViewChildren,
  ViewContainerRef,
  isDevMode, NgZone,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { KendoDropDownSearchableComponent } from '@framewerx/kendo-angular-grid';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbDialogRef } from '@nebular/theme';
import {
  CrewDayTask,
  Project,
  ProjectBreakdown,
  ProjectChange,
  ProjectTask,
  ProjectWorkTask,
} from '../../../../../sitewerx-api';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'ngx-new-day-task',
  templateUrl: './new-day-task-dialog.component.html',
  styleUrls: [ './new-day-task-dialog.component.scss' ],
})
export class NewDayTaskDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  isDevMode = isDevMode();
  @Input() hasWorkShiftId: boolean = true;
  @Input() defaultProjectId: number;
  @Input() defaultWorkShiftId: number;
  @Input() allowChangeProject: boolean = true;
  newTaskForm: UntypedFormGroup;
  protected destroy$ = new Subject<boolean>();

  @ViewChild('vc', {read: ViewContainerRef, static: true}) viewContainerRef: ViewContainerRef;

  projectFilter: CompositeFilterDescriptor;
  projectTemplate = (dataItem: Project) => `${ dataItem.name } - ${ dataItem.description }`;
  divisionTemplate = (dataItem: ProjectBreakdown) => `${ dataItem.name } - ${ dataItem.description }`;
  ewoTemplate = (dataItem: ProjectChange) => `${ dataItem.name } - ${ dataItem.description }`;

  @Input()
  item: CrewDayTask;
  @Input()
  clearMissing = true;

  @Input()
  disableTask = false;

  constructor(
    protected ref: NbDialogRef<NewDayTaskDialogComponent>,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
  }

  @ViewChildren(KendoDropDownSearchableComponent)
  kendoDropDownSearchableComponents: QueryList<KendoDropDownSearchableComponent>;

  ngOnInit() {
    this.newTaskForm = this.fb.group({
      workShiftId: [ null, [ Validators.required ] ],
      projectTask: this.fb.group({
        projectId: [ null, [ Validators.required ] ],
        projectBreakdownId: [ null ],
        projectWorkTaskId: [ null, [ Validators.required ] ],
        projectChangeId: [ null ],
        isActive: [ true ],
        isProgressRequired: [ false ],
        tag: [ null ],
      }),
    });

    this.projectFilter = {
      logic: 'and',
      filters: [ {
        field: 'deletionDate eq null and isActive eq true',
        operator: 'eq', value: true,
      } ],
    };

    this.projectIdControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (f) => {
          this.projectSetup(f);
          this.clearMissing = true;
        },
      });

    this.projectWorkTaskIdControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (f) => {
          const projectWorkTask = this.kendoDropDownSearchableComponents.find(item => item.name === 'projectWorkTaskId');
          const proj = projectWorkTask.selectedObject as ProjectWorkTask;
          if (proj != null) {
            this.newTaskForm.get('projectTask.isProgressRequired').patchValue(proj.isProgressRequired);
          }
        },
      });
  }

  projectSetup(projectId) {
    const clearMissing = this.clearMissing;

    const projectBreakdown = this.kendoDropDownSearchableComponents.find(item => item.name === 'projectBreakdownId');
    if (projectBreakdown != null) {
      if (clearMissing) {
        projectBreakdown.clearMissing = clearMissing;
      }
      projectBreakdown.filter = {
        logic: 'and',
        filters: [ {
          field: `projectId eq ${ projectId } and deletionDate eq null and isActive eq true`,
          operator: 'eq', value: true,
        } ],
      };
      projectBreakdown.list.filterChange.emit('');
    }

    const projectChange = this.kendoDropDownSearchableComponents.find(item => item.name === 'projectChangeId');
    if (projectChange != null) {
      if (clearMissing) {
        projectChange.clearMissing = clearMissing;
      }
      projectChange.filter = {
        logic: 'and',
        filters: [ {
          field: `projectId eq ${ projectId } and deletionDate eq null and isActive eq true`,
          operator: 'eq', value: true,
        } ],
      };
      projectChange.list.filterChange.emit('');
    }
    const projectWorkTask = this.kendoDropDownSearchableComponents.find(item => item.name === 'projectWorkTaskId');
    if (projectWorkTask != null) {
      if (clearMissing) {
        projectWorkTask.clearMissing = clearMissing;
      }
      projectWorkTask.filter = {
        logic: 'and',
        filters: [ {
          field: `projectId eq ${ projectId } and deletionDate eq null and isActive eq true`,
          operator: 'eq', value: true,
        } ],
      };
      projectWorkTask.list.filterChange.emit('');
    }
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {

    this.ngZone.onStable
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (k) => {
          const project = this.kendoDropDownSearchableComponents.find(item => item.name === 'projectId');
          const proj = project.selectedObject as Project;
          if (proj != null) {
            if (proj.tasksRequireDivision) {
              this.projectBreakdownIdControl.setValidators([ Validators.required ]);
            } else {
              this.projectBreakdownIdControl.clearValidators();
            }
            this.projectBreakdownIdControl.updateValueAndValidity();
          }
        },
      });

    if (this.item != null) {
      this.newTaskForm.patchValue(this.item);
    } else {
      if (this.defaultProjectId != null) {
        this.newTaskForm.patchValue({projectTask: {projectId: this.defaultProjectId}}, {emitEvent: true});
      }
      if (this.defaultWorkShiftId != null) {
        this.newTaskForm.patchValue({workShiftId: this.defaultWorkShiftId});
      }
    }

    if (this.disableTask) {
      this.newTaskForm.get('projectTask').disable();
    } else {
      this.newTaskForm.get('projectTask').enable();
      if (!this.allowChangeProject) {
        this.newTaskForm.get('projectTask.projectId').disable();
      } else {
        this.newTaskForm.get('projectTask.projectId').enable();
      }
    }
    if (this.hasWorkShiftId) {
      this.newTaskForm.get('workShiftId').enable();
    } else {
      this.newTaskForm.get('workShiftId').disable();
    }
    this.newTaskForm.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  get projectIdControl(): AbstractControl {
    return this.newTaskForm.get('projectTask.projectId');
  }

  get projectBreakdownIdControl(): AbstractControl {
    return this.newTaskForm.get('projectTask.projectBreakdownId');
  }

  get projectWorkTaskIdControl(): AbstractControl {
    return this.newTaskForm.get('projectTask.projectWorkTaskId');
  }

  get projectChangeIdControl(): AbstractControl {
    return this.newTaskForm.get('projectTask.projectChangeId');
  }

  get tagControl(): AbstractControl {
    return this.newTaskForm.get('projectTask.tag');
  }

  get workShiftIdControl(): AbstractControl {
    return this.newTaskForm.get('workShiftId');
  }

  public isRequired(control: AbstractControl): boolean {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      return !!(validator && validator.required && control.enabled);
    }

    return false;
  }

  confirm() {
    this.ref.close(this.newTaskForm.getRawValue());
  }

  dismiss() {
    this.ref.close(null);
  }

  private static disableFieldIfNotRequired(targetControl: AbstractControl, required: boolean) {
    if (required) {
      targetControl.enable();
    } else {
      targetControl.disable();
    }
  }

  projectWorkTask(item: ProjectWorkTask): string {
    return `${ item.workTask.description } (${ item.workTask.name })`;
  }
}
