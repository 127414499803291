<div class="header-container"
     [class.left]="position === 'normal'"
     [class.right]="position === 'inverse'"
     style="overflow: hidden;">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="navigation">
            <nb-icon icon="bars"></nb-icon>
        </a>
        <div class="logo" (click)="goToHome()">Site<span>Werx</span></div>
    </div>
    <!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
    <!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
    <!--  </nb-select>-->
    <div class="font-w-bold logo text-uppercase" [ngClass]="{'text-success': isDevMode, 'text-danger': !isDevMode}"
         *ngIf="envProfile != null && envProfile.trim().length > 0">
        {{ envProfile }}
    </div>
    <div class="font-w-bold logo text-uppercase text-primary"
         *ngIf="title != null && title.trim().length > 0">
        {{ title }}
    </div>
</div>

<div class="header-container">

    <nb-actions
        size="medium"
        [class.right]="position === 'normal'"
        [class.left]="position === 'inverse'">
        <nb-action icon="bell" pack="fas"
                   [badgeText]="errorService.alerts.length > 0 ? '!' : null"
                   [badgeStatus]="errorService.alerts.length > 0 ? 'danger' : 'basic'"
                   (click)="displayErrorAlerts()">
        </nb-action>
        <!--<nb-action *ngIf="isDevMode" icon="fa-cog" class="toggle-layout" (click)="toggleSettings()"></nb-action>-->
        <nb-action *ngIf="accessChecker.isGranted('view','profile') | async">
            <nb-user [nbContextMenu]="userMenu" [name]="user?.name" [onlyPicture]="userPictureOnly"
                     [picture]="user?.picture" class="ms-2 sm-ms-0"></nb-user>
        </nb-action>
        <!--<nb-action class="control-item" disabled icon="notifications"></nb-action>-->
        <!--<nb-action class="control-item" icon="email"></nb-action>-->
        <!--<nb-action class="control-item">-->
        <!--<nb-search type="rotate-layout" (click)="startSearch()"></nb-search>-->
        <!--</nb-action>-->
    </nb-actions>
</div>
