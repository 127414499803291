import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { KendoDropDownSearchableComponent } from '@framewerx/kendo-angular-grid';
import { CrewDay, CrewLead } from '../../../../../sitewerx-api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-crew-date-selector',
  templateUrl: './crew-date-selector.component.html',
  styleUrls: [ './crew-date-selector.component.scss' ],
})
export class CrewDateSelectorComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  selectDate = true;
  @Input()
  take = 10;

  @ViewChild(KendoDropDownSearchableComponent)
  crewLeadDropDownComponent: KendoDropDownSearchableComponent;

  @Input()
  crewDay: CrewDay = {};

  @Input()
  isDisabled = false;

  @Input()
  isDateDisabled = false;

  @Input()
  isCrewSelectDisabled = false;

  @Input()
  isCrewSupervisor = false;
  @Input()
  allowNull = false;

  @Output()
  selectedDate = new EventEmitter<Date>();

  @Output()
  selectCrewLeader = new EventEmitter<number>();

  @Output()
  crewDayChange = new EventEmitter<CrewDay>();

  crewLeadTemplate = (dataItem: CrewLead) => `${ dataItem.crewLeader.surName }, ${ dataItem.crewLeader.givenName }`;
  protected destroy$ = new Subject<boolean>();

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    if (this.crewLeadDropDownComponent != null) {
      this.crewLeadDropDownComponent.valueChange
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (k) => {
            this.crewDay.crewLead = this.crewLeadDropDownComponent.selectedObject;
          },
        });
    }
  }

  dateSelected($event: Date) {
    this.crewDay.workDate = $event;
    this.selectedDate.emit($event);
    this.crewDayChange.emit(this.crewDay);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  crewLeadChange(k: any) {
    this.crewDay.crewLeadId = k;
    this.selectCrewLeader.emit(k);
    this.crewDayChange.emit(this.crewDay);
  }
}
