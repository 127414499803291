import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {MsalRedirectComponent} from '@azure/msal-angular';

const routes: Routes = [
    {path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},
    {path: '', redirectTo: 'pages', pathMatch: 'full'},
    {path: '**', redirectTo: 'pages'},
    {path: 'auth', component: MsalRedirectComponent},
];

const config: ExtraOptions = {
    enableTracing: false,
    useHash: false,
    relativeLinkResolution: 'legacy',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
