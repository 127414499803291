import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveEnd, Router} from '@angular/router';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService implements OnDestroy {

    appInsights: ApplicationInsights;
    private routerSubscription: Subscription;

    constructor(
        private router: Router,
    ) {
        if (environment.appInsights && environment.appInsights.instrumentationKey) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsights.instrumentationKey,
                    autoTrackPageVisitTime: true,
                },
            });
            this.appInsights.loadAppInsights();

            this.routerSubscription = this.router.events
                .pipe(
                    filter((event) => event instanceof ResolveEnd),
                )
                .subscribe({
                    next: (event: ResolveEnd) => {
                        const activatedComponent = this.getActivatedComponent(event.state.root);
                        if (activatedComponent) {
                            this.logPageView(`${ activatedComponent.name } ${ this.getRouteTemplate(event.state.root) }`, event.urlAfterRedirects);
                        }
                    },
                });
        }
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    setUserId(userId: string) {
		if (this.appInsights) {
        	this.appInsights.setAuthenticatedUserContext(userId);
        }
    }

    clearUserId() {
		if (this.appInsights) {
	        this.appInsights.clearAuthenticatedUserContext();
	    }
    }

    logPageView(name?: string, uri?: string) {
		if (this.appInsights) {
	        this.appInsights.trackPageView({name, uri});
	    }
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }

}
