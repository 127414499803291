import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {NbAuthModule} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {AzureRoleProvider} from '../pages/auth/azure/azure-role-provider';
import {of as observableOf} from 'rxjs';

import {DataModule} from './data/data.module';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService} from './utils/analytics.service';
import {LayoutService} from "./utils/layout.service";
import {CustomDailyLogEquipmentService} from './api/custom-daily-log-equipment.service';

export const NB_CORE_PROVIDERS = [
    ...DataModule.forRoot().providers,
    ...NbAuthModule.forRoot({

        strategies: [],
        forms: {},
    }).providers,

    NbSecurityModule.forRoot({
        accessControl: {
            guest: {
                public: ['*'],
            },
            user: {
                parent: '',
                view: ['usersonly', 'profile'],
            },
            crewlead: {
                parent: 'user',
                view: 'time',
                edit: 'time',
            },
            dataentry: {
                parent: 'crewlead',
                edit: ['dataentry', 'alltime'],
            },
            supervisor: {
                parent: 'dataentry',
                edit: 'approve',
            },
            projectlead: {
                parent: 'supervisor',
                edit: 'displaySettings',
            },
            administrator: {
                parent: 'projectlead',
                view: ['*'],
                create: ['*'],
                edit: ['*'],
                remove: ['*'],
                admin: ['system'],
            },
            superadmin: {
                parent: 'administrator',
                admin: ['*'],
            },
        },
    }).providers,

    {
        provide: NbRoleProvider, useClass: AzureRoleProvider,
    },
    AnalyticsService,
    LayoutService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        NbAuthModule,
    ],
    declarations: [],
})
export class CoreModule {

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
