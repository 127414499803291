import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {NbRoleProvider} from '@nebular/security';
import {Observable, of} from 'rxjs';

@Injectable()
export class AzureRoleProvider implements NbRoleProvider {

    constructor(private authService: MsalService) {
    }

    getRole(): Observable<string[]> {
        if (this.authService.instance.getActiveAccount() != null) {
            return of(this.authService.instance.getActiveAccount().idTokenClaims['roles'] as unknown as string[]);
        } else {
            return of(['guest']);
        }
    }
}
