/**
 * Sitewerx API
 * An API for Sitewerx.
 *
 * The version of the OpenAPI document: v1
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ExpenseType = 'LOA' | 'Meals' | 'InitialTerminalTravel' | 'DailyTravel';

export const ExpenseType = {
    Loa: 'LOA' as ExpenseType,
    Meals: 'Meals' as ExpenseType,
    InitialTerminalTravel: 'InitialTerminalTravel' as ExpenseType,
    DailyTravel: 'DailyTravel' as ExpenseType
};

