import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ErrorResponse } from '../../../../sitewerx-api';
import { NbGlobalLogicalPosition, NbToastRef, NbToastrService } from '@nebular/theme';
import { take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ErrorService extends Subject<ErrorResponse | any> {

  alerts: ErrorResponse[] = [];
  nbToasts: NbToastRef[] = [];
  displayed: boolean = false;
  notificationButtonClicked = false;

  constructor(private logger: NGXLogger,
              private toastrService: NbToastrService) {
    super();
  }

  //displays alerts
  displayAlerts() {
    if ((this.notificationButtonClicked && !this.displayed) || !this.displayed) {
      for (var notification of this.alerts) {
        this.showToast(0, notification, 'Error encountered!');
      }
      setTimeout(() => {
        this.notificationButtonClicked = false;
        this.displayed = true;
      }, 300);
    }
    //if bell clicked and toast is already present
    else if (this.notificationButtonClicked && this.displayed) {
      for (var toast of this.nbToasts) {
        toast.close();
      }
      this.displayed = false;
      this.notificationButtonClicked = false;
    }
    //if bell is not clicked and toast is already present
    else {
      for (var toast of this.nbToasts) {
        toast.close();
      }
      this.displayed = false;
      this.notificationButtonClicked = true;
    }
  }

  setAlerts(notifications) {
    this.alerts = notifications;
  }

  //Toast showing method taking in duration, message and title inputs and outputing a toast message
  showToast(duration, notification, title) {
    const nbToast = this.toastrService.danger(
      notification.message,
      title,
      {duration: duration, icon: '', toastClass: 'fa-xmark', position: NbGlobalLogicalPosition.TOP_END},
    );
    this.nbToasts.push(nbToast);
    nbToast.onClick()
      .pipe(take(1))
      .subscribe({
        next: e => {
          // Remove the notification
          let index = this.alerts.indexOf(notification);
          if (index !== -1) {
            this.alerts.splice(index, 1);
            this.nbToasts.splice(index, 1);
            this.displayed = false;
          }
        },
      });
  }
}
