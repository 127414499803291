import {Location} from '@angular/common';
import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

declare const ga: any;

@Injectable()
export class AnalyticsService implements OnDestroy {

    protected destroy$ = new Subject<boolean>();

    private enabled: boolean;

    constructor(private location: Location, private router: Router) {
        this.enabled = false;
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    trackPageViews() {
        if (this.enabled) {
            this.router.events
                .pipe(
                    takeUntil(this.destroy$),
                    filter((event) => event instanceof NavigationEnd),
                )
                .subscribe({
                    next: () => {
                        ga('send', {hitType: 'pageview', page: this.location.path()});
                    },
                });
        }
    }

    trackEvent(eventName: string) {
        if (this.enabled) {
            ga('send', 'event', eventName);
        }
    }
}
