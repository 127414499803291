<ng-container #vc></ng-container> <!-- vc ng-container is needed to properly display the dropdown popup -->
<nb-card class="dialog-card nb-layout-column" style="min-width: 75vw;">
  <nb-card-header>
    <h2>Add Project Work Package</h2>
    <button (click)="dismiss()"
            type="button"
            class="btn btn-danger float-end">
      <span class="fas fa-times fa-2x"></span>
    </button>
  </nb-card-header>
  <nb-card-body>
    <div class="col-12 pb-2">
      <form name="form" [formGroup]="newTaskForm">
        <div *ngIf="hasWorkShiftId" class="row">
          <div class="mb-3" [ngClass]="{'required': isRequired(workShiftIdControl)}">
            <label class="h3 control-label" for="inputShift">WorkShift</label>
            <framewerx-kendo-drop-down-searchable
                [allowNull]="false"
                [table]="'workShift'"
                [prefetchDataFromServer]="true"
                [sort]="[{field: 'name', dir: 'asc'}]"
                [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true"
                [searchFields]="['name', 'description']"
                formControlName="workShiftId"
                name="workShiftId"
                id="inputShift"
                [ngClass]="{'w-100': true}"
                [popupSettings]="{ width: 'auto', appendTo: viewContainerRef }"
            ></framewerx-kendo-drop-down-searchable>
          </div>
        </div>
        <div formGroupName="projectTask">
          <div class="row">
            <div class="mb-3" [ngClass]="{'required': isRequired(projectIdControl)}">
              <label class="h3 control-label" for="inputProject">Project</label>
              <framewerx-kendo-drop-down-searchable
                  [allowNull]="false"
                  [table]="'project'"
                  [prefetchDataFromServer]="true"
                  [sort]="[{field: 'name', dir: 'asc'}]"
                  [textField]="'name'"
                  [valueField]="'id'"
                  [valuePrimitive]="true"
                  [searchFields]="['name', 'description']"
                  [filter]="projectFilter"
                  formControlName="projectId"
                  name="projectId"
                  id="inputProject"
                  [itemtemplate]="projectTemplate"
                  [valuetemplate]="projectTemplate"
                  [ngClass]="{'w-100': true}"
                  [popupSettings]="{ width: 'auto', appendTo: viewContainerRef }"
              ></framewerx-kendo-drop-down-searchable>
            </div>
          </div>
          <div class="row">
            <div class="mb-3" [ngClass]="{'required': isRequired(projectWorkTaskIdControl)}">
              <label class="h3 control-label" for="inputTask">Task</label>
              <framewerx-kendo-drop-down-searchable
                  [allowNull]="false"
                  [table]="'projectWorkTask'"
                  [prefetchDataFromServer]="true"
                  [sort]="[{field: 'workTask.name', dir: 'asc'}]"
                  [textField]="'workTask.name'"
                  [valueField]="'id'"
                  [valuePrimitive]="true"
                  [searchFields]="['workTask.name', 'workTask.description']"
                  [itemtemplate]="projectWorkTask"
                  [params]="{$expand: 'workTask'}"
                  formControlName="projectWorkTaskId"
                  name="projectWorkTaskId"
                  id="inputTask"
                  [ngClass]="{'w-100': true}"
                  [popupSettings]="{ width: 'auto', appendTo: viewContainerRef }"
              ></framewerx-kendo-drop-down-searchable>
            </div>
          </div>
          <div class="row">
            <div class="mb-3" [ngClass]="{'required': isRequired(projectBreakdownIdControl)}">
              <label class="h3 control-label" for="inputBreakdown">Division</label>
              <framewerx-kendo-drop-down-searchable
                  [allowNull]="!isRequired(projectBreakdownIdControl)"
                  [table]="'projectBreakdown'"
                  [prefetchDataFromServer]="true"
                  [sort]="[{field: 'name', dir: 'asc'}]"
                  [textField]="'name'"
                  [valueField]="'id'"
                  [valuePrimitive]="true"
                  [searchFields]="['name', 'description']"
                  formControlName="projectBreakdownId"
                  name="projectBreakdownId"
                  id="inputBreakdown"
                  [itemtemplate]="divisionTemplate"
                  [valuetemplate]="divisionTemplate"
                  [ngClass]="{'w-100': true}"
                  [popupSettings]="{ width: 'auto', appendTo: viewContainerRef }"
              ></framewerx-kendo-drop-down-searchable>
            </div>
          </div>
          <div class="row">
            <div class="mb-3" [ngClass]="{'required': isRequired(projectChangeIdControl)}">
              <label class="h3 control-label" for="inputChange">EWO</label>
              <framewerx-kendo-drop-down-searchable
                  [allowNull]="true"
                  [table]="'projectChange'"
                  [prefetchDataFromServer]="true"
                  [sort]="[{field: 'name', dir: 'asc'}]"
                  [textField]="'name'"
                  [valueField]="'id'"
                  [valuePrimitive]="true"
                  [searchFields]="['name', 'description']"
                  [itemtemplate]="ewoTemplate"
                  [valuetemplate]="ewoTemplate"
                  formControlName="projectChangeId"
                  name="projectChangeId"
                  id="inputChange"
                  [ngClass]="{'w-100': true}"
                  [popupSettings]="{ width: 'auto', appendTo: viewContainerRef }"
              ></framewerx-kendo-drop-down-searchable>
            </div>
          </div>
          <div class="row">
            <div class="mb-3" [ngClass]="{'required': isRequired(tagControl)}">
              <label class="h3 control-label" for="inputAssetTag">Asset Tag</label>
              <input
                  class="form-control"
                  formControlName="tag"
                  id="inputAssetTag"
                  [ngClass]="{'w-100': true}"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="d-print-none bg-white" *ngIf="isDevMode" style="white-space: pre-wrap;">
      <p>Form valid? {{newTaskForm?.valid}}</p>
      <p>Form pristine? {{newTaskForm?.pristine}}</p>
      <p>Form value: {{newTaskForm?.value | json}}</p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton status="primary" (click)="confirm()" [disabled]="newTaskForm.invalid">Confirm</button>
    <button nbButton (click)="dismiss()" class="ms-3">Cancel</button>
  </nb-card-footer>
</nb-card>
