import {Component, Input, isDevMode, OnDestroy, OnInit} from '@angular/core';

import {NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {Subject, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../@core/data/users.service';
import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {LOGOUT_TITLE} from '../../../pages/pages-menu';
import {map, takeUntil} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';
import {NbAccessChecker} from '@nebular/security';
import {HeaderTitleService} from '../../services/header-title.service';
import {LayoutService} from '../../../@core/utils/layout.service';
import {User} from '../../../@core/data/user';
import {ErrorService} from '../../../pages/shared/errors/error.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() position = 'normal';

    private destroy$ = new Subject<void>();
    userPictureOnly: boolean = false;

    user: any | User;
    title: string;

    envProfile = environment.environment;

    isDevMode = isDevMode();
    themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
        {
            value: 'cosmic',
            name: 'Cosmic',
        },
        {
            value: 'corporate',
            name: 'Corporate',
        },
    ];
    currentTheme = 'corporate';

    userMenu: NbMenuItem[] = [
        {title: 'Profile', url: 'https://portal.office.com/account/#personalinfo', target: '_blank'},
        {title: LOGOUT_TITLE},
    ];

    constructor(private sidebarService: NbSidebarService,
                private menuService: NbMenuService,
                private themeService: NbThemeService,
                private layoutService: LayoutService,
                private breakpointService: NbMediaBreakpointsService,
                private userService: UserService,
                public accessChecker: NbAccessChecker,
                private analyticsService: AnalyticsService,
                private headerService: HeaderTitleService,
                public errorService: ErrorService) {
    }

    ngOnInit() {
        this.userService.onUserChange().pipe(
        		takeUntil(this.destroy$),
        	)
            .subscribe({
                next: (user: any) => {
                    this.user = user;
                },
            });
        this.headerService.onHeaderChange()
            .subscribe({next: (title) => this.title = title});

        const {xl} = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
				takeUntil(this.destroy$),
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
            )
            .subscribe({next: (isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl});

        this.themeService.onThemeChange()
            .pipe(
                takeUntil(this.destroy$),
                map(({name}) => name),
            )
            .subscribe({next: themeName => this.currentTheme = themeName});
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    toggleSettings(): boolean {
        this.sidebarService.toggle(false, 'settings-sidebar');
        return false;
    }

    goToHome() {
        this.menuService.navigateHome();
        return false;
    }

    startSearch() {
        this.analyticsService.trackEvent('startSearch');
    }

    displayErrorAlerts() {
        this.errorService.notificationButtonClicked = true;
        this.errorService.displayAlerts();
    }

}
