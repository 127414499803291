<div [hidden]="crewDay === null" class="row">
  <div class="mb-3 col-md-12 col-lg-6">
    <label class="h4" for="crewLead">Crew {{(isCrewSupervisor) ? 'Supervisor' : 'Lead'}}</label>
    <framewerx-kendo-drop-down-searchable
        id="crewLead"
        [filterable]="true"
        class="w-100"
        [value]="crewDay?.crewLeadId"
        (valueChange)="crewLeadChange($event)"
        [table]="'crewLead'"
        [take]="take"
        [autoComplete]="false"
        [textField]="'crewLeader.surName'"
        [valueField]="'id'"
        [allowNull]="allowNull"
        [params]="{
          $expand: 'crewLeader($select=id,personnelNumber,givenName,surName,isActive)',
          $select: 'id,crewLeaderId,isActive,workShiftId,defaultProjectId,TimeApprovePersonnelId'
          }"
        [searchFields]="['crewLeader.surName','crewLeader.givenName']"
        [prefetchDataFromServer]="true"
        [itemtemplate]="crewLeadTemplate"
        [isReadOnly]="isDisabled || isCrewSelectDisabled"
        [valuePrimitive]="true"
        #crewLeadId>
    </framewerx-kendo-drop-down-searchable>
  </div>
  <div class="mb-3 col-md-12 col-lg-6">
    <label *ngIf="!selectDate" class="h4" for="crewDate">Selection date</label>
    <label *ngIf="selectDate" class="h4" for="crewDate">Select a date</label>
    <kendo-datepicker (valueChange)="dateSelected($event)"
                      id="crewDate"
                      [readonly]="isDisabled || isDateDisabled"
                      [value]="crewDay?.workDate"></kendo-datepicker>
    <p *ngIf="selectDate" class="mt-1">(Use Alt+↓ to open the calendar, ← and → to navigate, ↑ to increment
      and ↓ to decrement the value)</p>
  </div>
</div>
