import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import {NGXLogger} from 'ngx-logger';
import {Observable, from} from 'rxjs';
import {MsalCustomInterceptor} from '../../pages/auth/msal-custom-interceptor';

@Injectable()
export class MicrosoftGraphService {
    url = 'https://graph.microsoft.com/v1.0';

    constructor(private logger: NGXLogger, private authService: MsalService, private interceptor: MsalCustomInterceptor) {
    }

    getClient(): MicrosoftGraphClient.Client {
        return MicrosoftGraphClient.Client.init({
            authProvider: (done) => {
                const scopes = this.interceptor.getScopesForEndpoint(this.url, 'GET');
                this.authService.acquireTokenSilent({scopes: scopes})
                    .subscribe({
                        next: (result) => {
                            this.logger.trace(`MSAL token acquired silently for Microsoft Graph Service: ${ JSON.stringify(result) }`);
                            done(null, result.accessToken); // first parameter takes an error if you can't get an access token
                        },
                    });
            },
        });
    }

    getMe(): Observable<MicrosoftGraph.User> {
        const client = this.getClient();
        return from(client
            .api('me')
            .get()
            .then(((res) => res)),
        );
    }

    getMyPhoto(): Observable<MicrosoftGraph.ProfilePhoto> {
        const client = this.getClient();
        return from(client
            .api('me/photo')
            .get()
            .then(((res) => res)),
        );
    }

    sendMail(mail: MicrosoftGraph.Message) {
        const client = this.getClient();
        return from(client
            .api('me/sendmail')
            .post({message: mail}),
        );
    }

}
