import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { NbDialogModule, NbLayoutModule, NbThemeModule } from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { PendingChangesGuard } from './pending-changes-guard';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
// import { MomentModule } from 'ngx-moment';
import { environment } from '../environments/environment';
import { ApiModule, BASE_PATH, Configuration, ConfigurationParameters } from '../sitewerx-api';
import { CoreModule } from './@core/core.module';
import { GlobalErrorHandlerService } from './@core/utils/global-error-handler.service';
import { GlobalHttpErrorInterceptorService } from './@core/utils/global-http-error-interceptor.service';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, KENDO_ANGULAR_GRID_CONFIG } from './app.component';
import { MsalCustomInterceptor } from './pages/auth/msal-custom-interceptor';
import { ErrorService } from './pages/shared/errors/error.service';
import { kendoGridConfigFactory, SharedModule } from './pages/shared/shared.module';
import { KendoAngularGridModule } from '@framewerx/kendo-angular-grid';
import { BlobErrorHttpInterceptorService } from './@core/utils/blob-error-http-interceptor.service';
// import {ConnectionServiceModule, ConnectionServiceOptions, ConnectionServiceOptionsToken} from 'ngx-connection-service';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication, LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    apiKeys: {Authorization: null},
    basePath: environment.apiBaseUrl,
  };
  return new Configuration(params);
}

export function loggerCallback(logger: NGXLogger, logLevel: LogLevel, message: string, containsPii: boolean) {
  switch (logLevel) {
    case LogLevel.Error:
      logger.error(message);
      break;
    case LogLevel.Info:
      logger.info(message);
      break;
    case LogLevel.Verbose:
      logger.trace(message);
      break;
    case LogLevel.Warning:
      logger.warn(message);
      break;
    default:
      logger.log(message);
      break;
  }
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(logger: NGXLogger): IPublicClientApplication {
  const logLevel = environment.msalLoggingLevel || LogLevel.Info;
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureClientId,
      authority: environment.authority,
      redirectUri: `${ window.location.origin }/auth`,
      postLogoutRedirectUri: `${ window.location.origin }/pages/dashboard`,
      navigateToLoginRequestUrl: true, // default=true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // localStorage works across tabs but is less secure,
      storeAuthStateInCookie: isIE, // set to true for IE11
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          loggerCallback(logger, level, message, containsPii);
        },
        logLevel: logLevel, // LogLevel.Verbose for debugging
        piiLoggingEnabled: false, // true for debugging
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>(<[ string, string[] ][]>environment.protectedResourceMap);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    // authRequest: {
    //   scopes: ['user.read'],
    // },
    loginFailedRoute: '/auth/failed',
  };
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Levels: TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs', // TODO: Implement logging in server https://stackoverflow.com/a/53078568/2557554
      level: environment.loggingLevel,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    // ConnectionServiceModule,
    NgbModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    NbDialogModule.forRoot(),
    MsalModule,

    GridModule,
    DropDownsModule,
    DialogsModule,
    LayoutModule,
    DateInputsModule,
    TooltipModule,
    SharedModule,
    InputsModule,
    ApiModule.forRoot(apiConfigFactory),

    NbThemeModule.forRoot({name: 'corporate'}),
    NbLayoutModule,

    // MomentModule,
    KendoAngularGridModule.forRoot(kendoGridConfigFactory()),
  ],
  bootstrap: [ AppComponent, MsalRedirectComponent ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptorService,
      multi: true,
    },
    CldrIntlService,
    {
      provide: IntlService,
      useExisting: CldrIntlService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-CA',
    },
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: BASE_PATH, useValue: environment.apiBaseUrl},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalCustomInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ NGXLogger ],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    // {
    //   provide: ConnectionServiceOptionsToken,
    //   useValue: <ConnectionServiceOptions>{
    //     enableHeartbeat: true,
    //     heartbeatUrl: '//favicon.ico',
    //     requestMethod: 'head',
    //     heartbeatInterval: 30000,
    //     heartbeatRetryInterval: 1000,
    //   }
    // },
    ErrorService,
    PendingChangesGuard,
    {provide: KENDO_ANGULAR_GRID_CONFIG, useFactory: kendoGridConfigFactory},
  ],
})
export class AppModule {
}
