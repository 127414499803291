import {NbMenuItem} from '@nebular/theme';

export const LOGOUT_TITLE: string = 'Logout';

export const MENU_ITEMS: NbMenuItem[] = [
    {
        title: 'Dashboard',
        icon: 'tachometer-alt',
        link: '/pages/dashboard',
        home: true,
    },
];

export const UTILITY_ITEMS: NbMenuItem[] = [
  {
    title: 'UTILITIES',
    group: true,
  },
  {
    title: 'Reset App',
    icon: 'redo',
    link: '/pages/dashboard/reset',
  },
  {
    title: 'Help',
    icon: 'question-circle',
    url: 'https://framewerx.atlassian.net/l/cp/fgctZAcm',
    target: '_blank',
  },
];

export const AUTH_ITEMS: NbMenuItem[] = [
    {
        title: 'AUTHENTICATION',
        group: true,
    },
    {
        title: 'Login',
        icon: 'sign-in-alt',
        link: '/pages/auth/login',
        data: {
            permission: 'public',
            resource: 'login',
        },
    },
    {
        title: LOGOUT_TITLE,
        icon: 'sign-out-alt',
        data: {
            permission: 'view',
            resource: 'usersonly',
        },
    },
];

