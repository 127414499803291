import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CrewLead} from '../../../sitewerx-api';

@Injectable({
    providedIn: 'root',
})
export class HeaderTitleService {

    protected title: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor() {
    }

    publishHeader(title: string) {
        this.title.next(title);
    }

    onHeaderChange(): Observable<string> {
        return this.title;
    }
}
