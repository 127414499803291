export * from './appUser.service';
import { AppUserService } from './appUser.service';
export * from './bOMImportFile.service';
import { BOMImportFileService } from './bOMImportFile.service';
export * from './bOMImportQuarantine.service';
import { BOMImportQuarantineService } from './bOMImportQuarantine.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './country.service';
import { CountryService } from './country.service';
export * from './crewDailyLog.service';
import { CrewDailyLogService } from './crewDailyLog.service';
export * from './crewDay.service';
import { CrewDayService } from './crewDay.service';
export * from './crewDayPersonnel.service';
import { CrewDayPersonnelService } from './crewDayPersonnel.service';
export * from './crewDayPersonnelProjectExpenses.service';
import { CrewDayPersonnelProjectExpensesService } from './crewDayPersonnelProjectExpenses.service';
export * from './crewDayPersonnelTrade.service';
import { CrewDayPersonnelTradeService } from './crewDayPersonnelTrade.service';
export * from './crewDayProject.service';
import { CrewDayProjectService } from './crewDayProject.service';
export * from './crewDayTask.service';
import { CrewDayTaskService } from './crewDayTask.service';
export * from './crewDayTaskEquipment.service';
import { CrewDayTaskEquipmentService } from './crewDayTaskEquipment.service';
export * from './crewDayTaskProduct.service';
import { CrewDayTaskProductService } from './crewDayTaskProduct.service';
export * from './crewLead.service';
import { CrewLeadService } from './crewLead.service';
export * from './crewPersonnel.service';
import { CrewPersonnelService } from './crewPersonnel.service';
export * from './dailyLog.service';
import { DailyLogService } from './dailyLog.service';
export * from './dailyLogEquipment.service';
import { DailyLogEquipmentService } from './dailyLogEquipment.service';
export * from './equipment.service';
import { EquipmentService } from './equipment.service';
export * from './equipmentCategory.service';
import { EquipmentCategoryService } from './equipmentCategory.service';
export * from './equipmentSubCategory.service';
import { EquipmentSubCategoryService } from './equipmentSubCategory.service';
export * from './equipmentVendor.service';
import { EquipmentVendorService } from './equipmentVendor.service';
export * from './expense.service';
import { ExpenseService } from './expense.service';
export * from './form.service';
import { FormService } from './form.service';
export * from './metadata.service';
import { MetadataService } from './metadata.service';
export * from './personnel.service';
import { PersonnelService } from './personnel.service';
export * from './personnelTimeRecord.service';
import { PersonnelTimeRecordService } from './personnelTimeRecord.service';
export * from './personnelTradeUnion.service';
import { PersonnelTradeUnionService } from './personnelTradeUnion.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './projectBrassing.service';
import { ProjectBrassingService } from './projectBrassing.service';
export * from './projectBreakdown.service';
import { ProjectBreakdownService } from './projectBreakdown.service';
export * from './projectChange.service';
import { ProjectChangeService } from './projectChange.service';
export * from './projectCompanyTimeImport.service';
import { ProjectCompanyTimeImportService } from './projectCompanyTimeImport.service';
export * from './projectEquipmentRate.service';
import { ProjectEquipmentRateService } from './projectEquipmentRate.service';
export * from './projectExpense.service';
import { ProjectExpenseService } from './projectExpense.service';
export * from './projectPersonnel.service';
import { ProjectPersonnelService } from './projectPersonnel.service';
export * from './projectPremium.service';
import { ProjectPremiumService } from './projectPremium.service';
export * from './projectTask.service';
import { ProjectTaskService } from './projectTask.service';
export * from './projectTradeUnion.service';
import { ProjectTradeUnionService } from './projectTradeUnion.service';
export * from './projectWorkTask.service';
import { ProjectWorkTaskService } from './projectWorkTask.service';
export * from './stateProvince.service';
import { StateProvinceService } from './stateProvince.service';
export * from './systemOption.service';
import { SystemOptionService } from './systemOption.service';
export * from './timeImportQuarantine.service';
import { TimeImportQuarantineService } from './timeImportQuarantine.service';
export * from './tradeUnion.service';
import { TradeUnionService } from './tradeUnion.service';
export * from './workArea.service';
import { WorkAreaService } from './workArea.service';
export * from './workShift.service';
import { WorkShiftService } from './workShift.service';
export * from './workTask.service';
import { WorkTaskService } from './workTask.service';
export * from './workTrade.service';
import { WorkTradeService } from './workTrade.service';
export * from './workTradeUnion.service';
import { WorkTradeUnionService } from './workTradeUnion.service';
export const APIS = [AppUserService, BOMImportFileService, BOMImportQuarantineService, CompanyService, CountryService, CrewDailyLogService, CrewDayService, CrewDayPersonnelService, CrewDayPersonnelProjectExpensesService, CrewDayPersonnelTradeService, CrewDayProjectService, CrewDayTaskService, CrewDayTaskEquipmentService, CrewDayTaskProductService, CrewLeadService, CrewPersonnelService, DailyLogService, DailyLogEquipmentService, EquipmentService, EquipmentCategoryService, EquipmentSubCategoryService, EquipmentVendorService, ExpenseService, FormService, MetadataService, PersonnelService, PersonnelTimeRecordService, PersonnelTradeUnionService, ProductService, ProjectService, ProjectBrassingService, ProjectBreakdownService, ProjectChangeService, ProjectCompanyTimeImportService, ProjectEquipmentRateService, ProjectExpenseService, ProjectPersonnelService, ProjectPremiumService, ProjectTaskService, ProjectTradeUnionService, ProjectWorkTaskService, StateProvinceService, SystemOptionService, TimeImportQuarantineService, TradeUnionService, WorkAreaService, WorkShiftService, WorkTaskService, WorkTradeService, WorkTradeUnionService];
