import { NgxLoggerLevel } from 'ngx-logger';
import { LogLevel } from '@azure/msal-browser';

export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  environment: '',
  loggingLevel: NgxLoggerLevel.WARN,
  msalLoggingLevel: LogLevel.Warning,
  authority: 'https://login.microsoftonline.com/ddcdd9fc-eafc-4955-bdd4-1463255c7dc8/',
  azureClientId: '2b36d0f8-be06-46cf-b775-1d22b64cd72e',
  protectedResourceMap: [
    [ 'https://graph.microsoft.com/v1.0', [ 'User.Read', 'User.Read.All', 'User.ReadBasic.All', 'User.ReadWrite' ] ],
    [ '/api/*', [ 'api://2b36d0f8-be06-46cf-b775-1d22b64cd72e/Data.Read.All', 'api://2b36d0f8-be06-46cf-b775-1d22b64cd72e/Data.Write.All' ] ],
    // ... other scopes
  ],
  apiBaseUrl: window.location.origin,
  appInsights: {
    instrumentationKey: '42f93783-d143-4944-bf5b-c74594ae0971',
  },
  azureStorageConfig: {
    accountName: 'sitewerx',
    // accountKey: '',
    imageContainer: 'images',
    thumbnailContainer: 'thumbnails',
  },
  stemsBaseUrl: window.location.origin + '/_stems',
  companyLink: '',
};
